import type { Video } from "@/types/video";
import qs from "qs";
import { type StrapiResponseVideo, strapiInstance } from "./strapi.base";

/**
 * Get a single video by ID
 * @returns Video or boolean (if not found)
 * @param video_id
 */
export const getVideoById = async (
	video_id: string,
): Promise<Video | boolean> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					video_id: { $eq: video_id },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data[0] || false;
	} catch (error) {
		console.error(`Failed to fetch video by ID: ${error}`);
		return false;
	}
};

/**
 * Get a video by slug
 * @param slug
 * @returns Video or boolean (if not found)
 */
export const getVideoBySlug = async (
	slug: string,
): Promise<Video | boolean> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					slug: { $eq: slug },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data[0] || false;
	} catch (error) {
		console.error(`Failed to fetch getVideoBySlug: ${error}`);
		return false;
	}
};

/**
 * Get the most recently created Videos
 * @param page
 * @returns Video[]
 */
export const getRecentVideos = async (page = 1): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				fields: ["slug", "video_id"],
				filters: {
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["createdAt:DESC"],
				pagination: {
					page: page,
					pageSize: 20,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch recentVideos: ${error}`);
		return [];
	}
};

/**
 * Search videos by title
 * @param title
 * @returns Video[]
 */
export const searchVideosByTitle = async (title: string): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					title: { $containsi: title },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["bgg_expo:desc"],
				pagination: {
					page: 1,
					pageSize: 150,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch searchVideosByTitle: ${error}`);
		return [];
	}
};

/**
 * Get the videos sorted by top expo
 * @param gameId
 * @param pageSize
 * @return Video[]
 */
export const getGameTopVideos = async (
	gameId: number,
	pageSize?: number,
): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					game_id: { $eq: gameId },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["bgg_expo:desc", "yt_expo:desc"],
				pagination: {
					page: 1,
					pageSize: pageSize || 30,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch getGameTopVideos: ${error}`);
		return [];
	}
};

/**
 * Get the videos sorted by newest date
 * @param gameId
 * @param pageSize
 * @return Video[]
 */
export const getGameNewVideos = async (
	gameId: number,
	pageSize = 30,
): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					game_id: { $eq: gameId },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["post_date:desc"],
				pagination: {
					page: 1,
					pageSize: pageSize,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch getGameNewVideos: ${error}`);
		return [];
	}
};

export const cacheGameVideos = async (gameId: number): Promise<boolean> => {
	try {
		await strapiInstance.get(`/jobs/videos/game/${gameId}`);
		return true;
	} catch (error) {
		console.error(`Failed to fetch cacheGameVideos: ${error}`);
		return false;
	}
};

export const getNewVideosForChannel = async (
	channel_id: string,
	page?: number,
	pageSize?: number,
): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					channel_id: { $eq: channel_id },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["post_date:desc"],
				pagination: {
					page: page || 1,
					pageSize: pageSize || 30,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch getNewVideosForChannel: ${error}`);
		return [];
	}
};

export const searchVideosForChannel = async (
	channel_id: string,
	searchTerm: string,
	page?: number,
	pageSize?: number,
): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					channel_id: { $eq: channel_id },
					title: { $containsi: searchTerm },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["createdAt:DESC"],
				pagination: {
					page: page || 1,
					pageSize: pageSize || 30,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch searchVideosForChannel: ${error}`);
		return [];
	}
};

export const getTopVideosForChannel = async (
	channel_id: string,
): Promise<Video[]> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					channel_id: { $eq: channel_id },
					$or: [
						{
							hidden: {
								$null: true,
							},
						},
						{
							hidden: {
								$eq: false,
							},
						},
					],
				},
				sort: ["bgg_expo:desc"],
				pagination: {
					page: 1,
					pageSize: 20,
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseVideo>(
			`/videos?${query}`,
		);
		return response.data;
	} catch (error) {
		console.error(`Failed to fetch getNewVideosForChannel: ${error}`);
		return [];
	}
};
