import type { Channel } from "@/types/channel";
import type { Collection } from "@/types/collection";
import type { Video } from "@/types/video";

export interface Meta {
	pagination: {
		page: number;
		pageSize: number;
		pageCount: number;
		total: number;
	};
}

export interface StrapiResponseVideo {
	data: Video[];
	meta: Meta;
}

export interface StrapiResponseCollections {
	data: Collection[];
	meta: Meta;
}

export interface StrapiResponseChannel {
	data: Channel[];
	meta: Meta;
}

export const apiBaseUrl = "https://api.boardgamevideo.com/api";

// biome-ignore lint:
async function handleResponse(response: Response): Promise<any> {
	if (!response.ok) {
		console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
		throw new Error(response.statusText);
	}
	return response.json();
}

async function fetchWrapper<T>(url: string, options?: RequestInit): Promise<T> {
	try {
		const response = await fetch(`${apiBaseUrl}${url}`, {
			...options,
			headers: {
				"Content-Type": "application/json",
				...(options?.headers || {}),
			},
			next: { revalidate: 600 },
		});
		return await handleResponse(response);
	} catch (error) {
		console.error(`Fetch error: ${error}`);
		throw error;
	}
}

export const strapiInstance = {
	get: <T>(url: string, options?: RequestInit): Promise<T> =>
		fetchWrapper<T>(url, { ...options, method: "GET" }),
	// biome-ignore lint:
	post: <T>(url: string, body: any, options?: RequestInit): Promise<T> =>
		fetchWrapper<T>(url, {
			...options,
			method: "POST",
			body: JSON.stringify(body),
		}),
	// biome-ignore lint:
	put: <T>(url: string, body: any, options?: RequestInit): Promise<T> =>
		fetchWrapper<T>(url, {
			...options,
			method: "PUT",
			body: JSON.stringify(body),
		}),
	delete: <T>(url: string, options?: RequestInit): Promise<T> =>
		fetchWrapper<T>(url, { ...options, method: "DELETE" }),
};

export default strapiInstance;
