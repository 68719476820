import type { Channel } from "@/types/channel";
import qs from "qs";
import { type StrapiResponseChannel, apiBaseUrl } from "./strapi.base";
import { type StrapiResponseVideo, strapiInstance } from "./strapi.base";

/**
 * Normalize a search term by standardizing case, spaces, and equivalents.
 * @param term The input term to normalize.
 * @returns The normalized term.
 */
const normalizeSearchTerm = (term: string): string => {
	return term
		.toLowerCase()
		.replace(/&/g, "and") // Replace '&' with 'and'
		.replace(/[^a-z0-9\s]/g, "") // Remove special characters
		.replace(/\s+/g, " ") // Collapse extra spaces
		.trim();
};

/**
 * Generate bigrams (pairs of adjacent words) from a string.
 * @param term The input string to split into bigrams.
 * @returns An array of bigrams.
 */
const prepareSearchTerms = (term: string): string[] => {
	const words = term.split(/\s+/);

	// Generate bigrams if there are multiple words
	const bigrams =
		words.length > 1
			? words.slice(0, -1).map((word, i) => `${word} ${words[i + 1]}`)
			: [];

	// Combine single words and bigrams
	return [...words, ...bigrams];
};

export const getChannelDetails = async (
	channel_id: string,
): Promise<Channel | boolean> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					channel_id: { $contains: channel_id },
				},
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseChannel>(
			`/channels?${query}`,
		);
		return response.data[0] || false;
	} catch (error) {
		console.error(`Failed to fetch video by ID: ${error}`);
		return false;
	}
};

export const getChannelThumbnail = async (
	channel_id: string,
): Promise<Channel | boolean> => {
	try {
		const query = qs.stringify(
			{
				filters: {
					channel_id: { $eq: channel_id },
				},
				fields: ["thumbnail", "name"],
			},
			{ encodeValuesOnly: true },
		);
		const response = await strapiInstance.get<StrapiResponseChannel>(
			`/channels?${query}`,
		);
		return response.data[0] || false;
	} catch (error) {
		console.error(`Failed to fetch video by ID: ${error}`);
		return false;
	}
};

export const getChannelsBySubscribers = async (
	pageSize?: number,
): Promise<Channel[]> => {
	const query = qs.stringify(
		{
			filters: {
				$or: [
					{
						hidden: {
							$null: true,
						},
					},
					{
						hidden: {
							$eq: false,
						},
					},
				],
				video_count: {
					$gte: 10,
				},
			},
			sort: ["subscriber_count:desc"],
			pagination: {
				page: 1,
				pageSize: pageSize || 35,
			},
		},
		{
			encodeValuesOnly: true,
		},
	);
	const response = await strapiInstance.get<StrapiResponseChannel>(
		`/channels?${query}`,
	);
	return response.data || [];
};

/**
 * Search channels by Name using bigram matching.
 * @param channelName The name or partial name of the channel to search.
 * @returns A promise resolving to an array of Channel objects.
 */
export const searchChannelsByName = async (
	channelName: string,
): Promise<Channel[]> => {
	try {
		// Normalize and generate bigrams
		const normalizedTerm = normalizeSearchTerm(channelName);

		// Prepare search terms (single words + bigrams)
		const searchTerms = prepareSearchTerms(channelName);

		// Create filters for each search term
		const filters = searchTerms.map((term) => ({
			name: { $containsi: term },
			video_count: { $null: false },
		}));

		const query = qs.stringify(
			{
				filters: {
					$and: [
						{ $or: filters }, // Match any of the bigrams in the name
						{
							$or: [
								{ hidden: { $null: true } }, // Include channels with hidden as null
								{ hidden: { $eq: false } }, // Include channels with hidden as false
							],
						},
					],
				},
				sort: ["video_count:desc"],
				pagination: {
					page: 1,
					pageSize: 5,
				},
			},
			{ encodeValuesOnly: true },
		);

		const response = await strapiInstance.get<StrapiResponseChannel>(
			`/channels?${query}`,
		);
		return response.data || [];
	} catch (error) {
		console.error(`Failed to fetch searchChannelsByName: ${error}`);
		return [];
	}
};
