import slugify from "slugify";

const SLUG_CLEAN_REGEX = /[*+~.$%^&`<>\-=()\[\]'"?!{}:;@#\\#\/,|►▶■◆◆●♦★☆]/g;

export const slugifyString = (itemId: string | number, itemName = "") => {
	return slugify(`${itemId}-${itemName.toLowerCase()}`, {
		trim: true,
		remove: SLUG_CLEAN_REGEX,
	});
};
